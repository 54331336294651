/*
 * @Author: lixiaohu
 * @Date: 2024-07-23 17:37:02
 * @LastEditors: lixiaohu
 * @LastEditTime: 2024-07-25 13:55:17
 * @Description: Do not edit
 */
import base from "./base";
import md5 from "js-md5";
import request from "@/utils/request";

import requestJava from "@/utils/requestJava";
/** 登录*/
export function Login(apiUrl, data) {
  var ndate = new Date();
  var now = ndate.getTime();
  var something = "/api/Account/ValidLogin/" + base.appid + "/" + now;
  var message = (base.SecretKey + something).replace(/\//g, "").toLowerCase();
  var Sign = md5(message).toLowerCase();
  let url = apiUrl + `/api/Account/ValidLogin/${base.appid}/${Sign}/${now}`;
  return request({
    url,
    method: "post",
    data,
    isLogin: true,
  });
}
//刷新token
export function RefreshToken(apiUrl, params) {
  var ndate = new Date();
  var now = ndate.getTime();
  var something = "/api/Account/RefreshToken/" + base.appid + "/" + now;
  var message = (base.SecretKey + something).replace(/\//g, "").toLowerCase();
  var Sign = md5(message).toLowerCase();
  let url = apiUrl + `/api/Account/RefreshToken/${base.appid}/${Sign}/${now}`;
  return request({
    url,
    method: "get",
    params,
  });
}
//获取服务器时间戳
export function getServerTime(params) {
  let url = base.SoftApi + "api/Time/GetServerTime";
  return request({ url, method: "get", params });
}

//获取SoftApi
export function getSoftInfo(params) {
  let url = base.JavaApi + "/javaApi/soft/getSoftVersion/cross";
  return request({ url, method: "get", params, isLogin: true });
}
export function getSchoolSystemInfo(params) {
  const url = "/javaApi/homePage/getSchoolSystemInfo";
  return requestJava({
    url,
    method: "GET",
    params,
  });
}

import axios from "axios";
import qs from "qs";
import { ElMessage } from "element-plus";
import { RefreshToken } from "../api/login";
import base from "../api/base";
import Cookies from "js-cookie";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // 超时
  timeout: 120000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    //config.formData为true时用form data形式传参
    if (config.formData) {
      config.headers["Content-Type"] =
        "application/x-www-form-urlencoded;charset=UTF-8";

      config.data = qs.stringify(config.data);
    }
    config.headers["auth"] = Cookies.get("token");
    // 非登录的接口，都要增加id校验
    // 每个页面的Cookies是共享的，sessionStorage是独立的，
    // 通过其他链接直接登录时候，会把id在sessionStorage和Cookies都存一份
    // 只要两者不一样，就跳转到登录界面
    if (
      !config.isLogin &&
      Cookies.get("TeacherId") &&
      sessionStorage.getItem("TeacherId") &&
      Cookies.get("TeacherId") != sessionStorage.getItem("TeacherId")
    ) {
      throw { message: "登录失效，请重新登录", code: 1 };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 是否正在刷新的标记 -- 防止重复发出刷新token接口
let isRefreshing = false;
// 失效后同时发送请求的容器 -- 缓存接口
let subscribers = [];
// 刷新 token 后, 将缓存的接口重新请求一次
function onAccessTokenFetched(newToken) {
  subscribers.forEach((callback) => {
    callback(newToken);
  });
  // 清空缓存接口
  subscribers = [];
}
// 添加缓存接口
function addSubscriber(callback) {
  subscribers.push(callback);
}
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // if (res.data.ResponseStatus == 1) return res.data;
    // if (res.data.responseStatus == 1) return res.data;
    if (res.data.ResponseStatus == 5) {
      if (!isRefreshing) {
        isRefreshing = true;
        let apiUrl = Cookies.get("apiUrl");
        let userId = localStorage.getItem("TeacherId");
        let params = {
          oldToken: Cookies.get("token"),
          userId: userId,
          platId: base.appid,
        };
        RefreshToken(apiUrl, params)
          .then((res) => {
            console.log(res);

            Cookies.set("token", res.Data);

            onAccessTokenFetched(res.Data);
          })
          .catch(() => {
            localStorage.clear();
            window.location.href = "/LoginPage";
            ElMessage({
              message: "登录失效，请重新登录",
              type: "warning",
              duration: 5 * 1000,
            });
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态,
        // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口,
        // 此时, Promise的状态就会变成resolve
        addSubscriber((newToken) => {
          // 表示用新的token去替换掉原来的token
          res.config.headers["auth"] = newToken;
          // 替换掉url -- 因为baseURL会扩展请求url
          //response.config.url = response.config.url.replace(response.config.baseURL, '');
          // 用重新封装的config去请求, 就会将重新请求后的返回
          resolve(service(res.config));
        });
      });
      return retryOriginalRequest;
    } else {
      return res.data;
    }
  },
  (error) => {
    let { message, code } = error;

    if (code == 1) {
      window.location.href = "/LoginPage";
    }
    ElMessage({
      message: "出错啦:" + message,
      type: "error",
      duration: 5 * 1000,
    });

    return Promise.reject(error);
  }
);

export default service;

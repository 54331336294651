
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/LoginPage'
  },
  {
    path: '/LoginPage',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/HomePage',
    name: 'HomePage',
    component: () => import('@/views/HomePage/index.vue'),
    children: [
      {
        path: 'StagePage',
        name: 'StagePage',
        component: () => import('@/views/HomePage/StagePage.vue'),

      },
      {
        path: 'CoursePage',
        name: 'CoursePage',
        component: () => import('@/views/HomePage/CoursePage/index.vue'),

      },
      {
        path: 'CourseDetail',
        name: 'CourseDetail',
        component: () => import('@/views/HomePage/CoursePage/CourseDetail.vue'),

      },
      {
        path: 'MaterialPage',
        name: 'MaterialPage',
        component: () => import('@/views/HomePage/MaterialPage/index.vue'),

      },

      {
        path: 'MaterialDetail',
        name: 'MaterialDetail',
        component: () => import('@/views/HomePage/MaterialPage/MaterialDetail.vue'),

      },
      {
        path: 'ReviewPage',
        name: 'ReviewPage',
        component: () => import('@/views/HomePage/ReviewPage/index.vue'),
        children: [
             
              {
                path: "ReviewMessage",
                name: "ReviewMessage",
                component: () =>
                  import("@/views/HomePage/ReviewPage/ReviewMessage"),
              },
              {
                path: "ReviewList",
                name: "ReviewList",
                component: () => import("@/views/HomePage/ReviewPage/ReviewList"),
              },
        ]
      },
    ]

  },
  {
    path: '/CourseEdit',
    name: 'CourseEdit',
    component: () => import('@/views/CourseEdit/index.vue')
  },
  {
    path: '/CoursePreview',
    name: 'CoursePreview',
    component: () => import('@/views/CoursePreview.vue')
  },
  {
    path: '/NewPreview',
    name: 'NewPreview',
    component: () => import('@/views/NewPreview.vue')
  },
  { path: '/:catchAll(.*)', component: () => import('@/views/PageError.vue') },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

/*
 * @Author: feiManBa 13803895859@163.com
 * @Date: 2024-07-25 17:23:05
 * @LastEditors: feiManBa 13803895859@163.com
 * @LastEditTime: 2024-08-02 16:16:09
 * @FilePath: /courseCenterWeb/src/api/base.js
 * @Description: 
 */
const appid = "7fa73c88-ed3c-4691-b8ad-b7e58ba65c56";
const SecretKey = "305b2023-2f95-4e51-9287-6907c0b65184";
const VersionName = "4.0.6";

let host = window.location.host;
let regApi = /zaichengzhang.net|joyssom.com/;
let SoftApi = ''
let JavaApi = ''
if (regApi.test(host)) {
  SoftApi = "https://softapi.zaichengzhang.net/";
  JavaApi = "https://javaclassapi.zaichengzhang.net";
} else {
  SoftApi = "https://softapi.ejiang.com:9001/";
  JavaApi = "https://javaclassapi.rb.ejiang.com:9151";
}

const VersionNum = 42763;
export default {
  SecretKey,
  appid,
  VersionName,
  SoftApi,
  VersionNum,
  JavaApi,
};

<template>
  <router-view v-slot="{ Component }">
    <component ref="pageRef" :is="Component" :key="$router.path + $route.query.t ? $route.query.t : ''" />
  </router-view>
</template>
<script setup>
import { ref } from 'vue'

const pageRef = ref()
</script>
<style lang="scss">
@import url('@/assets/font/font.css');

html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  overflow: auto;
}

#app {
  min-width: 1000px;
}

* {
  box-sizing: border-box;
  font-family: PingFang SC-Regular;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  // display: none;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
//滚动条滑块
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: RGBA(250, 220, 224, 1);
}
</style>
<style lang="scss">
/* 下拉菜单弹窗的公共样式 */
.leftSelectPopper {
  // max-width: 200px!important;
  // min-width: 120px!important;
  inset: 52px auto auto 0px !important;
  border-radius: 15px 15px 15px 15px !important;
  background: #fff7ce !important;
  box-shadow: none !important;
  border: none !important;
  overflow: hidden !important;

  .el-popper__arrow::before {
    background: #fff7ce !important;
    border: none !important;
  }

  .el-select-dropdown__item {
    color: #eeaf00 !important;

    &:hover {
      background-color: #eeaf00 !important;
      color: #fff7ce !important;
    }

    &.hover {
      background-color: #eeaf00 !important;
      color: #fff7ce !important;
    }
  }
}
/* 输入框历史记录下拉筛选 */
.autocompletePopper {
  border-radius: 15px 15px 15px 15px !important;
  background: #fff7ce !important;
  box-shadow: none !important;
  border: none !important;
  overflow: hidden !important;

  .el-autocomplete-suggestion {
    width: 200px;
  }

  .el-popper__arrow::before {
    background: #fff7ce !important;
    border: none !important;
  }

  li {
    color: #eeaf00 !important;

    &:hover {
      background-color: #eeaf00 !important;
      color: #fff7ce !important;
    }

    &.hover {
      background-color: #eeaf00 !important;
      color: #fff7ce !important;
    }
  }
}
</style>

import * as at from "array.prototype.at";
at.shim();

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import VueKonva from "vue-konva";
import { createPinia } from "pinia";

import Clipboard from "v-clipboard";
import base from "@/api/base";
import { Base64 } from "@/utils/base64";

import { Login, getSoftInfo } from "@/api/login";
import { ElMessage } from "element-plus";
import PageLoading from "@/components/PageLoading.vue";
import Cookies from "js-cookie";

const instance = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  instance.component(key, component);
}

// import { useStore } from "@/store";

router.beforeEach((to, from, next) => {
  let token = Cookies.get("token");
  const home = Cookies.get("apiUrl");

  if (to.path === "/LoginPage") {
    next();
  } else {
    // 从外部链接跳进来的时候，无论原来是否登录，直接重新请求，覆盖旧的登录
    if (to.fullPath.includes("fromType")) {
      const app2 = createApp(PageLoading);
      const loadingInstance = app2.mount(document.createElement("div"));
      document.body.appendChild(loadingInstance.$el);
      let string = to.href.split("?fromType=");
      let arr = new Base64().decode(string[1]).split("&");
      let obj = {};
      arr.forEach((e) => {
        obj[e.split("=")[0]] = e.split("=")[1];
      });
      let versionNum = process.env.VUE_APP_BUILD || 0;
      let versionName = process.env.VUE_APP_VER;
      getSoftInfo({
        appId: base.appid,
        version: versionNum,
        userType: 1,
      }).then((res) => {
        if (res.code === 200) {
          //缓存到本地 apiUrl
          Cookies.set("apiUrl", res.data.apiUrl);

          //缓存到本地 fileUrl
          localStorage.setItem("fileUrl", res.data.fileUrl);
          
          Login(res.data.apiUrl, {
            UserName: obj.UserName,
            Password: obj.Password,
            AppId: base.appid,
            AppVersion: versionName,
            DeviceId: "FED2A2C7-E9D2-4229-B452-E1FB18B36724",
            OSName: "",
            VersionName: "",
          }).then((res) => {
            if (res.Data.LoginStatus === 0) {
              ElMessage({
                message: "用户名不存在",
                type: "warning",
              });
            } else if (res.Data.LoginStatus === 1) {
              ElMessage({
                message: "密码错误",
                type: "error",
              });
            } else if (res.Data.LoginStatus === 2) {
              next({
                path: "/CourseEdit",
                query: {
                  coursewareId: obj.coursewareId,
                },
              });
              Cookies.set("token", res.Data.Token);
              // 每个页面的Cookies是共享的，sessionStorage是独立的，
              // 通过其他链接直接登录时候，会把id在sessionStorage和Cookies都存一份
              // 只要两者不一样，就跳转到登录界面

              Cookies.set("TeacherId", res.Data.TeacherId);
              sessionStorage.setItem("TeacherId", res.Data.TeacherId);

              localStorage.setItem("userInfo", JSON.stringify(res.Data));

              setTimeout(() => {
                loadingInstance.$el.remove();

                app2.unmount();
              }, 1000);
              ElMessage({
                message: "登录成功已验证",
                type: "success",
              });

              //登录以后，增加定时器，在登录失效的时候，自动跳转到登录页面
              let timer = setInterval(() => {
                let token = Cookies.get("token");
                const home = Cookies.get("apiUrl");
                if (
                  token &&
                  home &&
                  sessionStorage.getItem("TeacherId") &&
                  Cookies.get("TeacherId") ==
                    sessionStorage.getItem("TeacherId")
                ) {
                  //
                } else {
                  ElMessage.error("登录失效，请重新登录");
                  router.push("/LoginPage");
                  clearInterval(timer);
                }
              }, 1000 * 60 * 10);
            } else if (res.Data.LoginStatus === 3) {
              ElMessage({
                message: "登录成功未验证",
                type: "waring",
              });
            } else if (res.Data.LoginStatus === 4) {
              ElMessage({
                message: "登录设备超出限制",
                type: "waring",
              });
            } else if (res.Data.LoginStatus === 5) {
              ElMessage({
                message: "没有班级",
                type: "waring",
              });
            }
          });
        }
      });
    } else {
      if (token && home) {
        next();
      } else {
        ElMessage.error("登录失效，请重新登录");
        next({
          path: "/LoginPage",
        });
      }
      // if (
      //   // 每次路由刷新，先看有没有sessionStorage
      //   // 没有sessionStorage，就证明是新开的页面，看是否有token和home，有就接着走，没有就跳登录
      //   // 如果有sessionStorage，证明是已经打开的页面，就只需要看和cookie里的id一不一致就行了
      //   sessionStorage.getItem("TeacherId")
      //     ? Cookies.get("TeacherId") == sessionStorage.getItem("TeacherId")
      //     : token && home
      // ) {
      // 根据人员权限，动态加载审批列表的路由,并存在store里面
      // 在跳转路由的时候，如果页面刷新了，store就会清空，然后需要重新手动添加

      // const store = useStore();

      // if (!store.routeList || store.routeList.length == 0) {
      // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // let children;

      // if (userInfo.IsDisplayAuth) {
      //   children = [
      //     {
      //       path: "ReviewList",
      //       name: "ReviewList",
      //       component: () => import("@/views/HomePage/ReviewPage/ReviewList"),
      //     },
      //     {
      //       path: "ReviewMessage",
      //       name: "ReviewMessage",
      //       component: () =>
      //         import("@/views/HomePage/ReviewPage/ReviewMessage"),
      //     },
      //   ];
      // } else {
      //   children = [
      //     {
      //       path: "ReviewMessage",
      //       name: "ReviewMessage",
      //       component: () =>
      //         import("@/views/HomePage/ReviewPage/ReviewMessage"),
      //     },
      //   ];
      // }

      // router.addRoute("HomePage", {
      //   path: "ReviewPage",
      //   name: "ReviewPage",
      //   component: () => import("@/views/HomePage/ReviewPage"),
      //   children,
      // });

      // store.routeList = JSON.parse(JSON.stringify(children));
      // next({ path: to.fullPath, replace: true, query: to.query });

      // } else {
      // next();

      // }

      // } else {
      //   ElMessage.error("登录失效，请重新登录");
      //   next({
      //     path: "/LoginPage",
      //   });
      // }
    }
  }
});

instance
  .use(createPinia())
  .use(Clipboard)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(VueKonva)
  .mount("#app");
